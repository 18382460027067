<template>
  <div class="pdf-page-wrapper">
    <div>
      <!-- <img :src="data.coverImage" class="coverImage" crossorigin="anonymous" /> -->
      <div class="cover-c">
        <div class="logo-wrapper">
          <!-- <img
            :src="data.backgroundColorImage"
            v-if="data.backgroundColorImage"
            crossorigin="anonymous"
          /> -->
          <div class="companyName1">
            {{ coverData.companyName }}
          </div>
          <div class="companyName companyName2">涉税风险专项服务检测报告</div>
        </div>

        <div class="bottom">
          <!-- <div class="time">杭州市富阳区税务局</div> -->
          <div class="times">
            {{ coverData.exeDate }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Cover",
  props: {
    data: Object,
    coverData: Object,
  },
  data() {
    return {
      backGroundImage: "",
    };
  },
  methods: {},
  computed: {
    coverClass() {
      return this.data.coverStructurePosition == 2 ? "center-wrapper" : "left-wrapper";
    },
  },
};
</script>

<style lang="stylus" scoped>
@import "~@/pages/pdfFrontGenerator/wordComponents/pdf.styl"


.coverImage {
  width 100%;
  height: 100%;
  display: block;
  z-index 2;
}

.cover {
  width 100%
  position absolute;
  top: 0;
  left: 0;
  top: 0;
  bottom: 0;
}

.logo {
  width 329px
}

.left-wrapper {


  .logo-wrapper {
    top 67px;
    left 67px
    position absolute
    display flex
    flex-direction column


    .companyName {
      font-size 36px
      color #060b55;
      font-weight bolder
      margin-top 30px
    }


    .title {
      font-size 64px
      color #060b55;
      font-weight bolder
      top 187px;
      left 67px
      position absolute
    }



  }
  .bottom {
    font-size: 33px;
    font-weight: bold
    color: #030852;
    line-height: 50px;
    position absolute
    bottom 67px;
    left 67px
    z-index 1
    margin-top: 30px;
  }
}

.center-wrapper {
  display flex
  flex-direction column
  justify-content center

  .logo {
    position absolute
    top 163px
    z-index 2
    left 300px;
    transform translateX(-50%)
  }

  .companyName {
    // position absolute
    // top 800px
    // left 50%;
    // transform translateX(-50%)
    // font-size: 22px;
    // font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    // font-weight: 600;
    // color: #000;
    // line-height: 36px;
  }
  .companyName2 {
    // top: 840px;
  }

  .bottom{
    // position absolute
    // left 50%;
    // bottom 280px;
    // transform translateX(-50%)
    // font-size: 24px;
    // font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    // font-weight: 600;
    // color: #000;
    // line-height: 36px;
    // .time{
    //   margin-top 24px
    // }
  }
}

.cover-c {
  font-family: "仿宋";
  font-size: 28px;
  color: #000;

  .logo-wrapper {
    img {
      height: 100px;
      margin-bottom: 300px;
      margin-top: 100px;
    }
  }

  .companyName1 {
    text-align: center;
    margin-top: 360px;
  }
  .companyName2 {
    text-align: center;
    margin-bottom: 240px;
  }
  .bottom {
    font-size: 28px;
    // margin-bottom: 100px;
    margin-top: 30px;
    margin-bottom: 220px;
    .time {
      text-align: center;
    }
    .times {
      margin-bottom: 160px;
      text-align: center;
    }
  }
}

.pdf-mode .title3 {
  font-family: "仿宋";
}
</style>
