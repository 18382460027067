<template>
  <div class="pdf-page-wrapper pdf-mode pdf-page summary" v-loading="!loaded">
    <div class="content-section">
      <div class="title3">一、企业基本情况</div>
      <table class="table">
        <tr>
          <td class="solid" style="width: 222px" bgcolor="#F1F1F1">企业名称</td>
          <td>{{ data.companyName }}</td>
        </tr>
        <tr>
          <td class="solid" bgcolor="#F1F1F1">纳税人识别号</td>
          <td>{{ data.taxNo }}</td>
        </tr>
        <tr>
          <td class="solid" bgcolor="#F1F1F1">所属行业</td>
          <td>{{ data.hymc }}</td>
        </tr>
        <tr>
          <td class="solid" bgcolor="#F1F1F1">纳税人资质</td>
          <td>{{ data.taxpayerQualificationType }}</td>
        </tr>
        <tr>
          <td class="solid" bgcolor="#F1F1F1">进出口信用</td>
          <td>{{ data.managementCategory }}</td>
        </tr>
        <tr>
          <td class="solid" bgcolor="#F1F1F1">注册资本</td>
          <td>{{ data.regCapital }}</td>
        </tr>
        <tr>
          <td class="solid" bgcolor="#F1F1F1">实收资本</td>
          <td>{{ data.actualCapital }}</td>
        </tr>
        <tr>
          <td class="solid" bgcolor="#F1F1F1">参保人数</td>
          <td>{{ data.socialStaffNum }}</td>
        </tr>
        <tr>
          <td class="solid" bgcolor="#F1F1F1">成立日期</td>
          <td>{{ data.estiblishTime }}</td>
        </tr>
        <tr>
          <td class="solid" bgcolor="#F1F1F1">核准日期</td>
          <td>{{ data.approvedTime }}</td>
        </tr>
      </table>
    </div>
    <div class="content-section">
      <div class="title3">二、风险概述</div>
      <div class="as-follow-text">
        <div>{{ data.taxText }}</div>
      </div>
      <financialRisk></financialRisk>
      <div class="as-follow-text">注意：上述为主要风险，更多内容详见下文</div>
    </div>

    <div class="footer"></div>
  </div>
</template>

<script>
import { getCompanyInfo, getFinancialRisk } from "@/api/threeYears";
import financialRisk from "../FinancialRisk";

export default {
  name: "Summary",
  components: {
    financialRisk,
  },
  data() {
    return {
      data: {},
      loaded: false,
      dataFinancialRisk: {},
    };
  },
  async mounted() {
    this.loaded = false;
    await this.loadCompanyInfo();
    await this.loadFinancialRisk();
    this.loaded = true;
  },
  methods: {
    async loadCompanyInfo() {
      const res = await getCompanyInfo({
        programId: this.$route.params.uuid,
        desensitizationStatus: this.$route.query.desensitization,
      });
      this.data = res.data;
    },
    async loadFinancialRisk() {
      const res = await getFinancialRisk({
        programId: this.$route.params.uuid,
      });
      this.dataFinancialRisk = res.data.data;
    },
  },
  computed: {
    dataTable() {
      if (this.dataFinancialRisk.dataTable) {
        this.dataFinancialRisk.dataTable.body.forEach((row, index) => {
          if (row[2]) {
            if (row[2].content === "高") {
              row[2].color = "#F53F3F";
            } else if (row[2].content === "中") {
              row[2].color = "#FF7D00";
            } else if (row[2].content === "低") {
              row[2].color = "#00B42A";
            }
          }
        });
      }
      return this.dataFinancialRisk.dataTable;
    },
  },
};
</script>

<style lang="stylus" scoped>
@import "~@/pages/pdfFrontGenerator/wordComponents/pdf.styl"


.content-section {
  width 1107px;
  margin 0 auto

  .table {
    width 100%

    td, th {
      font-size: 19px;
      font-family: "仿宋";
      font-weight: bold;
      color: #1D2129;
      line-height: 29px;
    }
  }
}

.as-follow-text {
  font-size: 16px;
  font-family: "仿宋";
  font-weight: 400;
  color: #1D2129;
  // margin 10px auto;
  line-height: 1;
  p{
    margin-bottom 16px
  }
}
.pdf-page-wrapper{
  background #fff
  padding 33px 0
  font-family: "仿宋";
}

.pdf-mode .title3 {
  font-family: "仿宋";
  font-weight: bold;
}

.summary .content-section .table td {
  font-size: 16px;
}
</style>
