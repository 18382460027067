<template>
  <div style="padding-bottom: 0" v-if="loaded">
    <div>
      <div class="table-wrappers fxdj">
        <!-- <BigPowerTable :data="dataTable" v-if="dataTable" class="table"></BigPowerTable> -->
        <table class="table" data-op-type="table" v-if="dataFinancialRisk">
          <thead>
            <tr>
              <th
                v-for="(v3, k3) in gaishuTableHeader"
                :colspan="v3.colspan"
                :rowspan="v3.rowspan"
                :style="{ textAlign: v3.align }"
              >
                {{ v3.content }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(v3, k3) in gaishuTableBody">
              <td
                v-for="(value, key) in v3"
                data-split-repeat="true"
                :colspan="value.colspan"
                :rowspan="value.rowspan"
                :style="{ textAlign: value.align, color: value.color }"
              >
                <span data-op-type="text">{{ value.content }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import "@/pages/Result/ThreeYearResult/global.styl";
import BigPowerSwitcher from "@/pages/Result/ThreeYearResult/components/BigPowerSwitcher";
import BigPowerLegends from "@/pages/Result/ThreeYearResult/components/BigPowerLegends";
import { getAutoRoutePushTo } from "@/utils/helper";
import Ciyun from "@/pages/Result/ThreeYearResult/financialAnalysis/Ciyun";
import BigPowerTable from "big-power-table";
import { getFinancialRisk } from "@/api/threeYears";

export default {
  name: "FinancialRisk",
  components: {
    BigPowerTable,
    BigPowerSwitcher,
    BigPowerLegends,
    Ciyun,
  },
  async mounted() {
    this.loaded = false;
    await this.loadFinancialRisk();
    this.loaded = true;
  },
  data() {
    return {
      showType: "table",
      data: {},
      loaded: false,
      // listHeader: [
      //   item[0].content == "个人所得税",||
      //   item[0].content == "企业发票财税风险分析",||
      //   item[0].content == "企业发票风险分析",||
      //   item[0].content == "企业基本信息",||
      //   item[0].content == "企业基本情况",||
      //   item[0].content == "企业基本情况分析",||
      //   item[0].content == "企业基础信息",||
      //   item[0].content == "企业所得税",||
      //   item[0].content == "企业财税风险分析",||
      //   item[0].content == "其他风险",||
      //   item[0].content == "印花税",||
      //   item[0].content == "土地使用税",||
      //   item[0].content == "土地增值税",||
      //   item[0].content == "增值税",||
      //   item[0].content == "房产税",||
      //   item[0].content == "收益、成本费用、利润不实风险",||
      //   item[0].content == "数据不勾稽",||
      //   item[0].content == "数据申报错误风险",||
      //   item[0].content == "未扣缴个税风险",||
      //   item[0].content == "税种风险",||
      //   item[0].content == "调整补税风险",||
      //   item[0].content == "财税风控",||
      //   item[0].content == "财税风险",||
      //   item[0].content == "财税风险分析",||
      //   item[0].content == "进项异常风险",||
      //   item[0].content == "重点数据统计",
      // ],
    };
  },
  methods: {
    handleCiyunClick(data) {
      this.$eventBus.$emit("switchToDetailTab", { anchor: data.id });
      //this.$router.push(getReportRoute(this.uuid,  {anchor: data.id}));
      //const fullURL = new URL(props.href, window.location.href).href;
      //location.href=fullURL;
    },
    async loadFinancialRisk() {
      const res = await getFinancialRisk({
        programId: this.$route.params.uuid,
      });
      this.data = res.data.data;
    },
  },
  computed: {
    dataFinancialRisk() {
      if (this.data.dataTable) {
        this.data.dataTable.body.forEach((row, index) => {
          if (row[2]) {
            if (row[2].content === "高") {
              row[2].color = "#F53F3F";
            } else if (row[2].content === "中") {
              row[2].color = "#FF7D00";
            } else if (row[2].content === "低") {
              row[2].color = "#00B42A";
            }
          }
        });
      }
      return this.data.dataTable;
    },
    gaishuTableHeader() {
      let header = this.dataFinancialRisk.header[0];
      header.splice(1, 1);
      return header;
    },
    gaishuTableBody() {
      let bodys = this.dataFinancialRisk.body;
      let filterBody = [];
      bodys.forEach((item, index) => {
        item.forEach((item) => {
          if (item.content == "高") {
            item.color = "#F53F3F";
          } else if (item.content == "中") {
            item.color = "#FF7D00";
          } else if (item.content == "低") {
            item.color = "#00B42A";
          } else {
            item.color = "#1d2129";
          }
        });
        if (
          // item[0].content == "企业发票风险分析" ||
          // item[0].content == "企业财税风险分析" ||
          item[0].content == "三年版模型" ||
          // item[0].content == "企业基本信息" ||
          // item[0].content == "财税风险" ||
          // item[0].content == "税种风险" ||
          // item[0].content == "重点数据统计"
          item[0].content == "个人所得税" ||
          item[0].content == "企业发票财税风险分析" ||
          item[0].content == "企业发票风险分析" ||
          item[0].content == "企业基本信息" ||
          item[0].content == "企业基本情况" ||
          item[0].content == "企业基本情况分析" ||
          item[0].content == "企业基础信息" ||
          item[0].content == "企业所得税" ||
          item[0].content == "企业财税风险分析" ||
          item[0].content == "其他风险" ||
          item[0].content == "印花税" ||
          item[0].content == "土地使用税" ||
          item[0].content == "土地增值税" ||
          item[0].content == "增值税" ||
          item[0].content == "房产税" ||
          item[0].content == "收益、成本费用、利润不实风险" ||
          item[0].content == "数据不勾稽" ||
          item[0].content == "数据申报错误风险" ||
          item[0].content == "未扣缴个税风险" ||
          item[0].content == "税种风险" ||
          item[0].content == "调整补税风险" ||
          item[0].content == "财税风控" ||
          item[0].content == "财税风险" ||
          item[0].content == "财税风险分析" ||
          item[0].content == "进项异常风险" ||
          item[0].content == "重点数据统计"

          // this.listHeader.indexOf(item[0].content > -1)
        ) {
          filterBody.push([item[0]]);
        } else if (item[0].rowspan == 1) {
          let isOneMore = 0;
          bodys.forEach((i) => {
            if (i[0].content == item[0].content) {
              isOneMore++;
            }
          });
          if (isOneMore > 1) {
            const list = item.slice(1);
            filterBody.push(list);
          } else {
            filterBody.push(item);
          }
        } else {
          filterBody.push(item);
        }
      });
      // console.log(filterBody);
      return filterBody;
    },
  },
};
</script>

<style lang="stylus" scoped>
.section-title
  height: 22px;
  color: #1D2129;
  line-height: 22px;
  display flex
  justify-content space-between
  border-bottom 1px solid #F2F3F5;
  padding-bottom 10px

  .title
    font-size: 16px;
    font-weight: 600;

.stats-wrapper
  background: #FFFFFF;
  border: 1px solid #F2F3F5;
  margin 20px 0
  padding 20px

  .time
    font-size: 14px;
    font-weight: 400;
    color: #86909C;
    line-height: 20px;

  .stats
    display flex
    margin-top 24px
    margin-bottom 7px

    .stats-item
      width 25%;
      display flex;
      align-items center
      justify-content center
      flex-direction column
      border-left 1px solid #F2F3F5;

      .number
        font-size: 30px;
        font-weight: 600;
        color: #1D2129;
        line-height: 42px;

      .text
        font-size: 14px;
        font-weight: 400;
        color: #4E5969;
        line-height: 20px;

    .stats-item:first-child
      border-left 0 none

.ciyun-wrapper
  .toolbar
    display flex
    justify-content flex-end

.table-wrapper
  background: #FFFFFF;
  margin 20px 0
  margin-bottom 0
  padding-bottom 24px
  margin-top: 0;

  .time {
    margin 20px 16px
    font-size: 14px;
    font-weight: 400;
    color: #86909C;
  }

  .table {
    >>> .bigPowerTable {
      max-height 720px
      overflow auto
      width: 100% !important;
    }

  }

.section {
  margin: 0 20px;
  margin-bottom: 10px;
}

.pdf-mode .bigPowerTable {
  width: 100%;
}

.table-wrappers {
  .table {
    width: 100%;
  }
}

.fxdj {
  .table thead tr th:last-child {
    white-space: nowrap;
  }
}
</style>
